import React, { useState, useEffect } from 'react';
import PageDiv from './components/pagecard';

import { AppWrapper } from './App.styled';
import { Media, Player } from 'react-media-player';
import CustomPlayPause from './components/music';
import pageDivContent from './components/content/content';

import ProgressBar from './components/progress';

function App() {
  const [bg, setBg] = useState('#292929');
  const [fg, setFg] = useState('white')
  const [scrollPercent, setScrollPercent] = useState(0);
  const changePage = (state, bg, fg) => {
    if (state) {
      setBg(bg);
      setFg(fg);
    }
  }

  const calcScrollPercent = () => {
    let offset = window.scrollY / (document.body.offsetHeight - window.innerHeight)
    setScrollPercent(offset * 100);
  }

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', calcScrollPercent)
    }
  }, [])

  return (
    <AppWrapper bg={bg} fg={fg}>
      <ProgressBar height="5px" fg={fg} scrollPercent={scrollPercent}/>
      <div style={{zIndex: '0'}}>
        <Media >
          <div>
            <div style={{display:'none'}}>
              <Player autoPlay loop src="https://www.youtube.com/watch?v=_O2PYw-tDs8" />
            </div>
            <div style={{  position: 'fixed', right: '10px', top: '10px', border: 'none'}}>
              <CustomPlayPause fg={fg}/>
            </div>

            {pageDivContent.map((item, key) => {
              return (
                <PageDiv key={key} short={ item.short || false } change={(state) => changePage(state, item.bg, item.fg)}>
                  {item.content}
                </PageDiv>
              );
            })}
          </div>
        </Media>
      </div>
    </AppWrapper>
  );
}

export default App;
