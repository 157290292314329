import React, { useState } from 'react';
import { PageDivContainer } from "./index.styled";
import { InView } from 'react-intersection-observer';

const PageDiv = ({ children, change, bg, fg, short }) => {
  const [inView, setInView] = useState(false);

  const handleChange = (inView) => {
    setInView(inView);
    change(inView);
  }
  return (      
    <InView as="div" onChange={(inView, entry) => handleChange(inView)} threshold={0.25}>
      <PageDivContainer short={short} fg={fg} bg={bg} fixed={inView}>
        {children}
      </PageDivContainer>
    </InView>
  );
}
export default PageDiv