import styled from 'styled-components';

export const PageDivContainer = styled.div`
  color: inherit;
  min-height: ${props => props.short ? '100vh' : '200vh'};
  display: flex;
  z-index: 0;
  align-items: center;
  justify-content: center;
  button {
    border: none;
    outline: none;
  }
`;