import React from 'react';
import { LightSpeed, Flip, Bounce } from 'react-reveal';

import Lottie from 'react-lottie-player'

import CustomPlayPause from '../music';

import musicAniData from './assets/music.json';

import TicTacToe from './tic-tac-toe';

const pageDivContent = [
  {
    bg: 'white',
    fg: 'black',
    short: true,
    content: 
    <Flip>
      Well hello there.
    </Flip>
  },
  {
    bg: '#92b4af',
    fg: 'white',
    content: 
    <LightSpeed>
      <div>
        And no, this is not a ripoff of what I made ages ago. There's something special here.<br />

        In any case ensure sure you be playing that music to enhance your experience. 
        <br />
        Press the button below if you haven't already.        
        <Lottie
          loop
          animationData={musicAniData}
          play
          style={{ width: 30, height: 30, display: 'inline-block' }}
        /><br /><br />
        <CustomPlayPause />
      </div>
    </LightSpeed>
  },
  {
    bg: '#6F4C5B',
    fg: 'white',
    content: 
    <Bounce>
      <div>
        So... I'm a bit late to all this but... good luck and happy late birthday.
      </div>
    </Bounce>
  },
  {
    bg: '#d9afaf',
    fg: 'white',
    short: true,

    content: 
    <div>
      <TicTacToe />
    </div>
  },
]

export default pageDivContent