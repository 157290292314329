import React, { useEffect, useState, useRef } from 'react';

import axios from 'axios';

import TicTacToeWrapper, { PrizeButtonTrigger } from './index.styled';

import Robert from '../assets/Robert.png';
import John from '../assets/John.png';

import Minimax from 'tic-tac-toe-minimax'
const { ComputerMove } = Minimax;


const TicTacToe = () => {
  const huPlayer = "X";
  const aiPlayer = "O";
  
  const symbols = {
      huPlayer: huPlayer,
      aiPlayer: aiPlayer
  }

  // const [difficulty, setDifficulty] = useState('Hard');
  const difficulty = 'Hard';
  const [losses, setLosses] = useState(0);
  const [board, setBoard] = useState([0,1,2,3,4,5,6,7,8]);
  const [turn, setTurn] = useState(huPlayer);
  const [hasEnded, sethasEnded] = useState(false);
  const isInitialMount = useRef(true);

  const resetBoard = () => {
    setBoard([0,1,2,3,4,5,6,7,8]);
    isInitialMount.current = true;
    setTurn(huPlayer);
    sethasEnded(false);
  }


  const handleMove = (key) => {
    let tempBoard = [];
    board.forEach(v => {tempBoard.push(v)});
    if (tempBoard[key] === key && !hasEnded) {
      tempBoard[key] = turn;

      setBoard(tempBoard);
    }
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const calcWinner = calculateWinner(board);

      if (calcWinner) {
        sethasEnded(calcWinner);
      } else {
        if (turn === huPlayer) {
          setTurn(aiPlayer);
        } else {
          setTurn(huPlayer);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(board)]);

  useEffect(() => {
    if (turn === aiPlayer) {
      const nextMove = ComputerMove( board, symbols, difficulty ); 
      handleMove(nextMove);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turn]);

  useEffect(() => {
    if (hasEnded) {
      if (hasEnded === aiPlayer) {
        setLosses(losses + 1);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEnded])

  return (
    <>
      <h1>Beat the AI once and you'll get your prize!</h1>
      {hasEnded === 'draw' ? <>It's a draw :O</> : hasEnded === 'X' ? 'You won!' : hasEnded === 'O' && 'You lost! >:D'  } <br />
      <button onClick={() => resetBoard()}>Reset</button>
      <TicTacToeWrapper>
        {[...Array(9).keys()].map((number, key) => {
          return (<button className='square' key={key} onClick={() => handleMove(number)}>
            {board[number] === 'X' ? <img className='squareIcon' src={Robert} alt='robert!'/> : board[number] === 'O' ? <img className='squareIcon' src={John} alt='john!'/> : ''}
            </button>
        )})}
      </TicTacToeWrapper>
      {losses > 10 && <>AHHAHAHAHA unfortunately for you, this AI is unbeatable and you've lost over 10 times. As pity for losing so much, I'm allowing you to claim your present by pressing the button below. ❤️)</>}
      {losses > 10 && <PrizeButton />}

    </>
  );
}

export const PrizeButton = () => {
  const [prizeCollected, setPrizeCollected] = useState(false);
  const [loading, setLoading] = useState(false);
/**
   * Given a method and data, will console log user data
   * @param {String} token idToken (jwt)
   * @param {string} data JSON Object data but as a string
   */
 const sendRequest = async (request) => {
  try {
    const response = await axios(request);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
const sendContactEmail = async () => {
  const url = ('https://api.johndao.com');
  const request = {
    method: 'post',
    url: url + '/email/present',
  };
  const response = await sendRequest(request);
  return response;
}

const handleClaimPresent = async () => {
  setLoading(true);
  const response = await sendContactEmail();
  setLoading(false);

  if (response && response.status === 200) {
    setPrizeCollected(<div>Winner winner chicken dinner! Check your email Robert :) LMK if anything is broken.</div>); // JSON data parsed by `data.json()` call
  } else {
    setPrizeCollected(<div>Uh oh something went wrong...</div>); // JSON data parsed by `data.json()` call
  }
}

  return (
    <PrizeButtonTrigger className='prizeButton' onClick={handleClaimPresent} disabled={loading}>
      { !prizeCollected ? <>Press here! </>
        : <>{prizeCollected}</>
      }
    </PrizeButtonTrigger>
  );
}
export function calculateWinner(squares) {
	const lines = [
		[0, 1, 2],
		[3, 4, 5],
		[6, 7, 8],
		[0, 3, 6],
		[1, 4, 7],
		[2, 5, 8],
		[0, 4, 8],
		[2, 4, 6],
	];
	for (let i = 0; i < lines.length; i++) {
		const [a, b, c] = lines[i];
		if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
			return squares[a];
		}
	}

  let drawCounter = 0;
  for (let i = 0; i < squares.length; i++) {
		if (squares[i] === 'X' || squares[i] === 'O') {
			drawCounter += 1;
		}
	}

  if (drawCounter === 9) {
    return 'draw';
  } else {
	  return null;
  }
}


export default TicTacToe;