import styled from 'styled-components';

export const ProgressWrapper = styled.div`
  background: ${props => props.fg || 'black'};
  position: fixed;
  top: 0;
  left: 0;
  height: ${props => props.height || '20px'};
  z-index: 1000;
  transition: 0.75s background ease-in-out;
  width: ${props => (`${props.scrollPercent}%`)};
`;