import styled from 'styled-components';

export const AppWrapper = styled.main`
  background-color: ${props => props.bg || '#292929'};
  color: ${props => props.fg || 'white'};
  transition: background 0.75s ease-in-out, color 0.75s ease-in-out;
  padding: 20px;
  text-align: center;
  font-family: 'Noto Sans', sans-serif;
  font-size: 2em;
  overflow-y: hidden;
`;
