import React from 'react';

import { ProgressWrapper } from './index.styled';

const ProgressBar = ({fg, height, scrollPercent}) => {
  return (
    <ProgressWrapper fg={fg} height={height} scrollPercent={scrollPercent}>

    </ProgressWrapper>
  );
}

export default ProgressBar