import styled from 'styled-components';

export const CustomPlayButton = styled.button`
  cursor: pointer;
  background: transparent;
  line-height: 25px;
  font-size: 1em;
  font-family: inherit;
  z-index: 1000;
  color: ${props => props.fg || 'white'};
  transition: 0.75s all ease-in-out;
  border: none;
  outline: none;
`;