import React, { useState } from 'react'
import { withMediaProps } from 'react-media-player'

import { CustomPlayButton } from './index.styled'
import { BsFillVolumeUpFill, BsFillVolumeMuteFill } from "react-icons/bs";

const PlayButton = (props) => {
  const { className, style, media } = props
  const [isPlaying, setIsPlaying] = useState(media.isPlaying)

  const dud = () => {
    if (false && isPlaying) {
      setIsPlaying();
    }
  }
  const handlePlayPause = () => {
    dud();
    props.media.playPause()
  }

  return (
    <CustomPlayButton
      type="button"
      className={className}
      style={style}
      onClick={handlePlayPause}
      fg={props.fg}
    >
      {media.isPlaying ? <BsFillVolumeUpFill /> : <BsFillVolumeMuteFill />}
    </CustomPlayButton>
  )
}

export default withMediaProps(PlayButton)