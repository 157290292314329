import styled from 'styled-components';

export const PrizeButtonTrigger = styled.button`
  padding: 10px;
  background: grey;
  color: white;
  font-family: inherit;
  font-size: 0.5em;
  border: none;
  outline: none;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;
  margin: 30px;
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🔥</text></svg>")
    16 0, auto;
  &:hover {
    transform: translateY(-10px);
  }
`;

export const TicTacToeWrapper = styled.div`
	border: "4px solid darkblue";
	border-radius: 10px;
	width: 600px;
	height: 600px;
	margin: 0 auto;
	display: grid;
	grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  
  @media (max-width: 750px) {
    width: 300px;
	  height: 300px;
  }

  // square button
  .square {
    height: 200px;
    width: 200px;
    margin: 0px;
    padding: 0px;
    border-radius: 0px;
    border: 2px solid black;
    cursor: pointer; 
    
    @media (max-width: 750px) {
      height: 100px;
      width: 100px;
    }
  }

  .squareIcon {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    animation: App-logo-spin infinite 5s linear;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default TicTacToeWrapper;